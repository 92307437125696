.drag-area{
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    background-image: linear-gradient(to right, #01006b , #0705ff);
    width: 99%;
    display: flex;
    align-items: center;
}

#app-icon {
    height: 100%;
    width: auto;
}

#app-title {
    color: white;
}

#app-controlBtns {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
}

#app-exitBtn {
    /* height: 100%;
    width: 25px; */
}