body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	border: 0;
  background-color: black;
}

:root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.material-icons-new {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
}

.icon-white {
  webkit-filter: contrast(4) invert(1);
  -moz-filter: contrast(4) invert(1);
  -o-filter: contrast(4) invert(1);
  -ms-filter: contrast(4) invert(1);
  -webkit-filter: contrast(4) invert(1);
          filter: contrast(4) invert(1);
}

.App {
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
}

.ShutDownActive {
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  background-color: black;
  z-index: 5;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

#you-died {
  -webkit-animation: youDied 5s;
          animation: youDied 5s;
}

@-webkit-keyframes youDied {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes youDied {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ShutDownInactive {
  display: none;
}

#desktop {
  background-color: #008080;

  width: 100%;
  height: 100%;

  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  max-height: 100%;
  overflow: auto;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  
}

#app-space {
  position: absolute;
  left: 10%;
  top: 10%;
}

.StartButton {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    max-width: 40%;
    padding: 5px;
    margin: 3px;
    background-color: #c0c0c0;
    border-left: 2px solid white;
    border-top: 2px solid white;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
}

.StartButtonActivated {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    max-width: 40%;
    padding: 5px;
    margin: 3px;
    background-color: #cacaca;
    border-left: 2px solid black;
    border-top: 2px solid black;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
}

.StartButton:hover {
    background-color: #cacaca;
}

.task {
    background-color: #c0c0c0;
}

.task:hover {
    background-color: #cacaca;
}
#canvas-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 70vw;
}

#canvas-wrap-contact {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 20rem;
}

#drag-area-wrap {
    position: relative;
    background-color: #c0c0c0;
    display: block;
    height: 3.2rem;
    border-left: 1px solid white;
    border-top: 1px solid white;
    border-right: 1px solid black;
}

#drag-area {
    position: absolute;
    width: 100%;
    background-image: -webkit-linear-gradient(left, #01006b , #0705ff);
    background-image: linear-gradient(to right, #01006b , #0705ff);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

#app-icon {
    height: 100%;
    width: auto;
}

#app-title {
    color: white;
    width: 100%;
    text-align: left;
}

#app-controlBtns {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
}

#app-exitBtn {
    margin-right: 20px;
    padding: 2px;
    background-color: #c0c0c0;
    border-left: 1.5px solid white;
    border-top: 1.5px solid white;
    border-right: 1.5px solid black;
    border-bottom: 1.5px solid black;
}
#experience {
    background-color: #c0c0c0;
    display: -webkit-flex;
    display: flex;
    text-align: left;
    padding: 0 1% 0 1%;
    border-left: 1px solid white;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    max-height: 70vh;
}

#left-panel {
    width: 80%;
    border-right: 2px solid black;
    overflow: scroll;
}

#right-panel {
    display: -webkit-flex;
    display: flex;
    width: 20%;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-left: 2px solid white;
}

.experienceButton {
    border-left: 2px solid white;
    border-top: 2px solid white;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 2px;
    width: 50%;
    margin: 5% 0 5% 0;
}
#projects {
    height: 30rem;
    background-color: #c0c0c0;
    display: -webkit-flex;
    display: flex;
    border: 8px solid #c0c0c0;
}

#file-tree {
    background-color: white;
    width: 29%;
    border: 1px solid black;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

#divider {
    background-color: #c0c0c0;
    width: 1%;
}

#file-contents {
    background-color: white;
    border: 1px solid black;
    width: 70%;
}

#file-tree-item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 10%;
}

#file-tree-item:hover {
    border: 1px solid black;
}

#file-tree-item-icon {
    /* height: 100%;
    width: auto; */
}

#file-tree-item-text {
    margin-left: 10%;
}

#expand {
    font-size: 24px;
}
#contact {
    background-color: #c0c0c0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.ContactMedium {
    display: -webkit-flex;
    display: flex;
    border-left: 2px solid white;
    border-top: 2px solid white;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 20px;
    margin: 20px;
}

#start-menu {
    background-color: #c0c0c0;
    position: absolute;
    /* height: 60%; */
    width: 40%;
    bottom: 5%;
    display: -webkit-flex;
    display: flex;
    z-index: 3;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    border-left: 2px solid white;
    border-top: 2px solid white;
}

#start-menu-label {
    background-image: -webkit-linear-gradient(left, #01006b , #0705ff);
    background-image: linear-gradient(to right, #01006b , #0705ff);
    width: 3rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
}

#label {
    -webkit-transform: rotate(270deg);
    color: white;
    font-size: 20px;
    margin-bottom: 50px;
}

.divider {
    border-top: 2px solid black;
    border-bottom: 2px solid white;
    width: 90%;
    margin-left: 5%;
}

#start-menu-apps {
    width: 100%;
}

.StartMenuAppIcon {
    height: 40%;
    width: auto;
}

.StartMenuApp {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 2%;
    text-align: left;
}

.StartMenuApp:hover {
    background-color: #01006b;
    color: white;
}

.StartMenuAppText {
    width: 50%;
    margin-left: 10%;
}
#references {
    /* height: 30rem; */
    background-color: black;
    border: 2px solid #c0c0c0;
}

.Statement {
    color: white;
    font-family: Consolas,monaco,monospace;
}

#continue {
    font-family: Consolas,monaco,monospace;
    background-color: black;

    -webkit-animation: flashText 0.8s infinite;

            animation: flashText 0.8s infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
}

@-webkit-keyframes flashText {
    0% {
        color: white;
    }
    100% {
        color: black;
    }
}

@keyframes flashText {
    0% {
        color: white;
    }
    100% {
        color: black;
    }
}
